import {
    LOAD_MONEYBIRD_USERS,
    MONEYBIRD_USERS_ERROR
} from "./constants";
import axios from "axios";

// Load user
export const loadMoneybirdusers = () => async (dispatch) => {
    try {
        const res = await axios.get(`/api/moneybird/users`);
        dispatch({
            type: LOAD_MONEYBIRD_USERS,
            payload: res
        })
    } catch (error) {
        dispatch({
            type: MONEYBIRD_USERS_ERROR,
            payload: [{msg: "error"}]
        });
    }
};
