export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const LOGOUT = "LOGOUT";

export const LOAD_ACTIVE_RECURRING_COMPANIES = "LOAD_ACTIVE_RECURRING_COMPANIES";
export const LOAD_ACTIVE_RECURRING_COMPANIES_ERROR = "LOAD_ACTIVE_RECURRING_COMPANIES_ERROR";

export const LOAD_COMPANIES_WITH_EXPIRED_INVOICES = "LOAD_COMPANIES_WITH_EXPIRED_INVOICES";
export const ERROR_LOAD_COMPANIES_WITH_EXPIRED_INVOICES = "ERROR_LOAD_COMPANIES_WITH_EXPIRED_INVOICES";

export const LOAD_MONEYBIRD_USERS = "LOAD_MONEYBIRD_USERS";
export const MONEYBIRD_USERS_ERROR = "MONEYBIRD_USERS_ERROR";

export const LOAD_MONEYBIRD_CONTACT = "LOAD_MONEYBIRD_CONTACT";
export const MONEYBIRD_CONTACT_ERROR = "MONEYBIRD_CONTACT_ERROR";

export const SET_CURRENT_WIK_BRIEF = "SET_CURRENT_WIK_BRIEF";
export const SET_CURRENT_WIK_BRIEF_FAIL = "SET_CURRENT_WIK_BRIEF_FAIL";

export const LOAD_CURRENT_PAGE = "LOAD_CURRENT_PAGE";
export const LOAD_CURRENT_PAGE_ERROR = "LOAD_CURRENT_PAGE_ERROR";