import { SET_ALERT, REMOVE_ALERT } from "../actions/constants";

const initialState = 
  {
    alertsID: null,
    alertsArray: []
  }

const alert = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case SET_ALERT:
        return {...state,
          alertsID: payload.id,
          alertsArray: [...state.alertsArray, payload.alert]
        }
      case REMOVE_ALERT:
        return {
          alertsID: null,
          alertsArray: []
        }      
      default:
        return state;
    }
  };
  
  export default alert;