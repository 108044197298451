import {
    SET_CURRENT_WIK_BRIEF,
    SET_CURRENT_WIK_BRIEF_FAIL
} from "./constants";

// Load user
export const setCurrentWikBrief = (moneybirdContact, invoices) => async (dispatch) => {
    try {
        const info = { moneybirdContact, invoices };
        dispatch({
            type: SET_CURRENT_WIK_BRIEF,
            payload: info
        })
    } catch (error) {
        dispatch({
            type: SET_CURRENT_WIK_BRIEF_FAIL,
            payload: [{msg: "error"}]
        });
    }
};