import React, { useEffect, useState } from 'react'
import Notes from './Notes'
import Moment from 'moment'
import { Table, Card, CardText, CardBody, CardTitle, Form, FormGroup, InputGroupText, Button, Label, Input, InputGroup } from 'reactstrap'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loadCompaniesWithExpiredInvoices, addNote } from '../../actions/companiesWithExpiredInvoices';

const SingleInvoice = ({currentPage, invoice, user, loadCompaniesWithExpiredInvoices, daysAgo}) => {


    const [newNote, setNewNote] = useState("");

    const handleSubmitNote = (e, main_id) => {
    
        e.preventDefault();
        const note = {"note":{"note":`[${user.name}]: ${newNote}`}};
        addNote({main_id, note});
        loadCompaniesWithExpiredInvoices(currentPage.title === 'remindedInvoices' ? 15 : 31);
    }

  return (
    <>
    <Card style={{padding: "15px", margin: "15px auto"}}>
        <CardBody>
        <CardTitle tag="h5">
            Factuur {invoice.invoice_id}
        </CardTitle>
        <CardText>
            <Table bordered>
            <thead>
                <tr>
                    <th>
                        Factuurnummer
                    </th>
                    <th>
                        Factuurdatum
                    </th>
                    <th>
                        Betreft
                    </th>
                    <th>
                        Totaal
                    </th>
                    <th>
                        Openstaand
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <a href={invoice.invoice_url} target="blank">{invoice.invoice_id}</a>
                    </td>
                    <td>
                        {Moment(invoice.invoice_date).format('DD-MM-YYYY')}
                    </td>
                    <td style={{maxWidth: "500px"}}>
                        {invoice.invoice_description}
                    </td>
                    <td>
                        € {invoice.invoice_total}
                    </td>
                    <td>
                        € {invoice.invoice_unpaid}
                    </td>
                </tr>
            </tbody>
        </Table>
        </CardText>
        <CardText>
            <Form onSubmit={(e) => handleSubmitNote(e, invoice.main_id)}>
                <InputGroup>
                    <Label style={{marginRight: "5px"}} for="notitie">Voeg notitie toe</Label>
                    <Input type='text' name="notitie" onChange={(e) => setNewNote(e.target.value)} />
                    <Button addonType="append" style={{ marginBottom: "5px"}} color="primary" type="submit">voeg toe</Button>
                </InputGroup>
            </Form>
        </CardText>
        {invoice.notes.length > 0 && <CardTitle tag="h5">Notities bij factuur {invoice.invoice_id}</CardTitle>}        
        <CardText>
            {invoice.notes.length > 0 && <Notes notes={invoice.notes} />}
        </CardText>
        </CardBody>
    </Card>     
    </>
  )
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    currentPage: state.currentPage
});

SingleInvoice.propTypes = {
    daysAgo: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    currentPage: PropTypes.object.isRequired
};

export default connect(mapStateToProps, { loadCompaniesWithExpiredInvoices})(SingleInvoice);