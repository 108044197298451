import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loadCompaniesWithExpiredInvoices } from "../../actions/companiesWithExpiredInvoices";
import SingleInvoice from "./SingleInvoice";
import { Card, CardBody, CardText, CardTitle, Col, Form, FormGroup, Button, CardSubtitle, Label, Input, Row } from "reactstrap";
import { loadMoneybirdusers } from "../../actions/moneybirdUsers";

const RemindedInvoices = ({ loadMoneybirdusers, companiesWithExpiredInvoices, loadCompaniesWithExpiredInvoices }) => {

    const [daysAgo, setDaysAgo] = useState(31);
    const [useRandomColor, setUseRandomColors] = useState(true);


    useEffect(() => {
        loadCompaniesWithExpiredInvoices(daysAgo);
        loadMoneybirdusers();
    }, [daysAgo]);

    let randomNumber = () => {
        Math.floor(Math.random()*(255 + 1))
    }
    function random(number){
        return Math.floor(Math.random()*number);;
    }
   
    function randomColor(){
        return useRandomColor ? 'rgba('+random(255)+','+random(255)+','+random(255)+', .33)' : 'rgba(255,255,255,1)';
    }

        let companyArr = [];
        companiesWithExpiredInvoices.companies.forEach(company => {
            let noteArr = [];
            company.invoices.forEach(invoice => {
                invoice.notes.forEach(note => {
                    noteArr.push(note.content);
                })
            });
        
            let moetOffline = false;
        
            const filterNotes = noteArr.filter(note =>
                note
                && (
                    note.toLowerCase().includes('offline')
                    || note.toLowerCase().includes('werkzaamheden staken')
                    || note.toLowerCase().includes('ba')
                    || note.toLowerCase().includes('ba:')
                    || note.toLowerCase().includes('betaalt uiterlijk')
                    || note.toLowerCase().includes('betaalafspraak')
                )
            );
        
            if(!filterNotes.length) {
                moetOffline = true;
            }
        
            companyArr.push({company: company, moetOffline: moetOffline});
            function compare( a, b ) {
                if ( a.moetOffline > b.moetOffline ){
                  return -1;
                }
                if ( a.moetOffline < b.moetOffline ){
                  return 1;
                }
                return 0;
              }
              
              companyArr.sort( compare );
        })
       console.log(companyArr);
    return (
      <>
      <h1>Facturen {daysAgo} dag(en) of ouder</h1>
      <p>
        Kleurtjes: <Button color={useRandomColor ? 'primary' : 'secondary'}
        onClick={(e) => {
            e.preventDefault();
            setUseRandomColors(!useRandomColor);
        }}
        >
            {useRandomColor ? "aan" : "uit"}
        </Button>
      </p>
      <Form onSubmit={(e) => e.preventDefault()}>
        <FormGroup>
            <Label for="days_ago">Aantal dagen oud</Label>
            <Input
                type="number"
                required
                name="days_ago"
                placeholder="15"
                value={daysAgo}
                onChange={(e) => setDaysAgo(e.target.value)}
            />
        </FormGroup>
      </Form>

      <p>Hieronder de facturen die {daysAgo} dagen of ouder zijn, ongeacht of ze de status 'verlopen' of 'herinnerd' hebben. Het kan eventjes duren voordat ze geladen zijn.</p>
      <p><strong>Aantal gevonden bedrijven met facturen van {daysAgo} dagen of ouder: </strong>{companiesWithExpiredInvoices.companies.length}</p>
      <div>
      {companyArr.map(item => {        
        const company = item.company;

        const bgColor = randomColor();
        return <Card key={company.id} style={{ backgroundColor: bgColor, padding: "15px", margin: "25px auto"}}>
            <CardBody>
            {item.moetOffline ? <h2 style={{fontWeight: 'bold', borderRadius: '5px', backgroundColor:  'red', marginBottom: "1em", color: 'white', padding: '50px', textAlign: 'center'}}>check of deze offline moet</h2> : ""}
                <CardTitle tag='h5'>
                    {company.company_name}
                </CardTitle>
            </CardBody>
            <CardText>
                <Card style={{backgroundColor: "transparent", border: "none"}}>
                    <CardTitle>
                    <Button
                        color="info"
                        href={`https://moneybird.com/234526464103416842/contacts/${company.contact_id}`}
                        target="blank"
                        size="sm"
                    >
                        Naar {company.company_name} in Moneybird
                    </Button>
                    <Button
                        color="primary"
                        href={`https://premstest.freshservice.com/search/all?term=${company.company_name}`}
                        target="blank"
                        size="sm"
                    >
                        Zoek {company.company_name} in CRM
                    </Button>
                    </CardTitle>
                </Card>
            <Card>
                <CardBody>
                    <CardText>
                    {company.contact_info.firstname && company.contact_info.lastname && <><strong>Naam: </strong> {company.contact_info.firstname} {company.contact_info.lastname}<br /></>}
                    {company.contact_info.phone && <><strong>Tel: </strong> {company.contact_info.phone}</>}
                    </CardText>
                </CardBody>
            </Card>
            {company.invoices.map(invoice => {
               return <SingleInvoice invoice={invoice} daysAgo={daysAgo}  />
            })}
            </CardText>
        </Card>     
      })}
      </div>
      </>
    )
}

const mapStateToProps = (state) => ({
    companiesWithExpiredInvoices: state.companiesWithExpiredInvoices,
});

RemindedInvoices.propTypes = {
    companiesWithExpiredInvoices: PropTypes.object.isRequired,
    loadCompaniesWithExpiredInvoices: PropTypes.func.isRequired,
    loadMoneybirdusers: PropTypes.func
};

export default connect(mapStateToProps, { loadCompaniesWithExpiredInvoices, loadMoneybirdusers })(RemindedInvoices);