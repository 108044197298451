import React, { useEffect } from "react";
import { connect } from "react-redux";

const Dashboard = ({alerts}) => {
  
  return(
    <>
      <h1 className="large text-primary">Dashboard</h1>
      <p className="lead">
        Welkom bij de Premiums MB Tool! De verschillende opties vind je onder 'overzichten' in het menu. Je kunt ook <a href='/expired-invoices'>direct naar verlopen facturen.</a>
      </p>
    </>
  )
};


export default connect()(Dashboard);