import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { login  } from '../../actions/auth'
import AlertComponent from '../layout/AlertComponent';
import { Row, Col, Button, Form, FormGroup, Label, Input  } from 'reactstrap';
import { Navigate } from 'react-router-dom';

function Login({login, isAuthenticated}) {

    const [formData, setFormData] = useState({
        email: "",
        password: ""
    });

    const {email, password} = formData;

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        login(email, password);
    }

    if(isAuthenticated) {
        return <Navigate to="/dashboard" />
    }

  return (
    <>
        <Row>
            <Col>
                <h1 className="display-3">Login</h1>
            </Col>
        </Row>
        <Row>
            <Col>
                <Form onSubmit={(e) => handleSubmit(e)}>
                    <FormGroup>
                        <Label for="email">E-mail</Label>
                        <Input type="email"
                            required
                            name="email"
                            placeholder="E-mail"
                            value={email}
                            onChange={(e) => handleChange(e)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="password">Password</Label>
                        <Input 
                            type="password"
                            required
                            name="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => handleChange(e)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Button color="primary" type="submit">Login</Button>
                    </FormGroup>
                </Form>
            </Col>
        </Row>
        <Row>
            <Col>
                <AlertComponent />
            </Col>
        </Row> 
        <Row>
            <p>Nog geen account? <a href="/register">Registreren</a></p>
        </Row>
    </>
  )
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
})

Login.propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool
}

export default connect(mapStateToProps, { login })(Login)
