import {
    LOAD_MONEYBIRD_CONTACT,
    MONEYBIRD_CONTACT_ERROR
} from "./constants";
import axios from "axios";

// Load user
export const loadMoneybirdContactByCompanyName = (company_name='') => async (dispatch) => {
    try {
        const res = await axios.get(`/api/moneybird/invoices/company_name/${company_name}`);
        dispatch({
            type: LOAD_MONEYBIRD_CONTACT,
            payload: res.data[0]
        })
    } catch (error) {
        dispatch({
            type: MONEYBIRD_CONTACT_ERROR,
            payload: [{msg: "error"}]
        });
    }
};
