import { combineReducers } from "redux";
import auth from './auth';
import moneybirdUsers from "./moneybirdUsers";
import companiesWithExpiredInvoices from "./companiesWithExpiredInvoices";
import moneybirdContact from "./moneybirdContact";
import alerts from "./alert";
import currentWikBrief from "./currentWikBrief";
import currentPage from "./currentPage";
import activeRecurringCompanies from "./activeRecurringCompanies";

const rootReducer = combineReducers({
    alerts,
    auth,
    moneybirdUsers,
    companiesWithExpiredInvoices,
    moneybirdContact,
    currentWikBrief,
    currentPage,
    activeRecurringCompanies
})

export default rootReducer