import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Route, Routes } from "react-router";
import NavbarComponent from "./components/layout/NavbarComponent";
import Landing from "./components/layout/Landing";
import { NotFound } from "./components/NotFound";

// Redux
import store from "./store";
import { Provider } from "react-redux";
import Dashboard from "./components/dashboard/Dashboard";
import PrivateRoute from "./components/routing/PrivateRoute";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import { Container } from 'reactstrap';
import { loadUser } from "./actions/auth";
import { setAuthToken } from "./utils/setAuthToken";
import ExpiredInvoices from "./components/invoices/ExpiredInvoices";
import RemindedInvoices from "./components/invoices/RemindedInvoices";
import Wik from "./components/wik/Wik";
import WikBrief from "./components/wik/WikBrief";
import ActiveRecurringCompanies from "./components/invoices/ActiveRecurringCompanies";

if (localStorage.getItem("token")) {
  setAuthToken(localStorage.getItem("token"));
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);
  return (
    <Provider store={store}>
      <Router>
        <NavbarComponent />
        <Container>
          <Routes>
            <Route
              path="/dashboard"
              element={<PrivateRoute component={Dashboard} />}
            />
            <Route
              path="/expired-invoices"
              element={<PrivateRoute component={ExpiredInvoices} />}
            />
            <Route
              path="/reminded-invoices"
              element={<PrivateRoute component={RemindedInvoices} />}
            />
            <Route
              path="/wik"
              element={<PrivateRoute component={Wik} />}
            />
            <Route
              path="/wik-brief"
              element={<PrivateRoute component={WikBrief} />}
            />
            <Route
              path="/active_recurring_companies"
              element={<PrivateRoute component={ActiveRecurringCompanies} />}
            />
            
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<Landing />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </Container>
      </Router>
    </Provider>
  );
};

export default App;
