import { SET_CURRENT_WIK_BRIEF, SET_CURRENT_WIK_BRIEF_FAIL } from "../actions/constants";

const initialState = {
    contact_id: null, 
    company_name: "",
    contact_info: {},
    selectedInvoices: [],
    loading: true
}

const currentWikBrief = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case SET_CURRENT_WIK_BRIEF:
        return {...state, 
            contact_id: payload.moneybirdContact.contact_id, 
            company_name: payload.moneybirdContact.company_name,
            contact_info: payload.moneybirdContact.contact_info,
          selectedInvoices: payload.invoices,
          loading: false
      };
      case SET_CURRENT_WIK_BRIEF_FAIL:
        return {
            contact_id: null, 
            company_name: "",
            contact_info: {},
            selectedInvoices: [],
            loading: false
        }
      default:
      return state;

    }
}

export default currentWikBrief;