import {
    LOAD_COMPANIES_WITH_EXPIRED_INVOICES,
    ERROR_LOAD_COMPANIES_WITH_EXPIRED_INVOICES
} from "./constants";
import axios from "axios";

// Load user
export const loadCompaniesWithExpiredInvoices = (days_ago = 31) => async (dispatch) => {
    try {
        const res = await axios.get(`/api/moneybird/invoices/expired/${days_ago}`);
        dispatch({
            type: LOAD_COMPANIES_WITH_EXPIRED_INVOICES,
            payload: res
        })
    } catch (error) {
        dispatch({
            type: ERROR_LOAD_COMPANIES_WITH_EXPIRED_INVOICES,
            payload: [{msg: "error"}]
        });
    }
};

export const addNote = async ({main_id, note}) => {
    try {
        const res = await axios.post(`/api/moneybird/invoices/add_note/${main_id}`, note);
        console.log(res);
    } catch (error) {
        console.error(error.message);
    }
}