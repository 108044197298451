import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Moment from 'moment';

const SingleNote = ({moneybirdUsers, note}) => {

    const [username, setUsername] = useState("Systeem");
    useEffect(() => {
        for (const key in moneybirdUsers.users) {
            if (Object.hasOwnProperty.call(moneybirdUsers.users, key)) {
                const element = moneybirdUsers.users[key];
                if(element.id === note.user_id) {                    
                    setUsername(element.name)
                }
            }
        }
    }, [])
   

  return (
    <tr>
        <td>
            {Moment(note.date).format('DD-MM-YYYY')}
        </td>
        <td>
            {username}
        </td>
        <td>
            {note.content}
        </td>
    </tr>
  )
}

const mapStateToProps = (state) => ({
    moneybirdUsers: state.moneybirdUsers,
});

SingleNote.propTypes = {
    moneybirdUsers: PropTypes.object.isRequired
};

export default connect(mapStateToProps, {})(SingleNote);