import { LOAD_COMPANIES_WITH_EXPIRED_INVOICES,
    ERROR_LOAD_COMPANIES_WITH_EXPIRED_INVOICES } from "../actions/constants";
  
  const initialState = {
      companies: [],
      errors: [],
      loading: true
  };
  
  const companiesWithExpiredInvoices = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case LOAD_COMPANIES_WITH_EXPIRED_INVOICES:
        state = {...state, 
          companies: payload.data, 
          loading: false
      };
      return state;
      case ERROR_LOAD_COMPANIES_WITH_EXPIRED_INVOICES:
          return {...state, 
              companies: [], 
              errors: payload.data, 
              loading: false
          };
      default:
        return state;
    }
  };
  
  export default companiesWithExpiredInvoices;