import {
    LOAD_CURRENT_PAGE,
    LOAD_CURRENT_PAGE_ERROR
} from "./constants";

// Load user
export const setPage = (pageTitle) => async (dispatch) => {
    try {
        dispatch({
            type: LOAD_CURRENT_PAGE,
            payload: pageTitle
        })
    } catch (error) {
        dispatch({
            type: LOAD_CURRENT_PAGE_ERROR,
            payload: [{msg: "error"}]
        });
    }
};