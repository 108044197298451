import { SET_ALERT, REMOVE_ALERT } from './constants';
import { v4 } from "uuid";

const setAlert =
  (alert, timeOut = 20000) =>
  (dispatch) => {
    const id = v4();
    dispatch({
      type: SET_ALERT,
      payload: {id, alert},
    });
    setTimeout(() => {
      dispatch({
        type: REMOVE_ALERT,
        payload: id,
      });
    }, timeOut);
  };

export { setAlert };