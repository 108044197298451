import React from "react";
import { Navigate } from "react-router-dom";
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

function Landing({isAuthenticated}) {
  if(isAuthenticated) {
    return <Navigate to="/dashboard" />
  } else {
    return (
      
      <div>
        <h1>Welkom bij de Premiums MB Tool.</h1>
        <p>Zodra je ingelogd bent en je account geactiveerd is kun je aan de slag.</p>
        <p>Heb je al een account? <a href="/login">Inloggen</a>
        <br />Nog geen account? <a href="/register">Registreren</a></p>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
})

Landing.propTypes = {
  isAuthenticated: PropTypes.bool
}

export default connect(mapStateToProps, {})(Landing)