import { LOAD_ACTIVE_RECURRING_COMPANIES, LOAD_ACTIVE_RECURRING_COMPANIES_ERROR } from "../actions/constants";

const initialState = { companies: [], loading: true };

const activeRecurringCompanies = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case LOAD_ACTIVE_RECURRING_COMPANIES:
        state = {...state, 
          companies: payload.data, 
          loading: false
      };
      return state;
      case LOAD_ACTIVE_RECURRING_COMPANIES_ERROR:
          return {...state, 
              companies: [], 
              errors: payload.data, 
              loading: false
          };
      default:
        return state;
    }
  };
  
  export default activeRecurringCompanies;