import {
    LOAD_ACTIVE_RECURRING_COMPANIES,
    LOAD_ACTIVE_RECURRING_COMPANIES_ERROR
} from "./constants";
import axios from "axios";

// Load user
export const loadActiveRecurringCompanies = () => async (dispatch) => {
    try {
        const res = await axios.get(`/api/moneybird/invoices/active_recurring`);
        dispatch({
            type: LOAD_ACTIVE_RECURRING_COMPANIES,
            payload: res
        })
    } catch (error) {
        dispatch({
            type: LOAD_ACTIVE_RECURRING_COMPANIES_ERROR,
            payload: [{msg: "error"}]
        });
    }
};