import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
  } from "../actions/constants";
  
  const initialState = {
    token: localStorage.getItem("token"),
    isAuthenticated: null,
    loading: true,
    user: null,
  };
  
  const auth = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case USER_LOADED:
        return {
          ...state,
          isAuthenticated: payload.verified,
          loading: false,
          user: payload,
        };
      case REGISTER_SUCCESS:
          localStorage.setItem("token", payload.token);
          return {
            ...state,
            ...payload,
            loading: false,
            isAuthenticated: false,
          };
      case LOGIN_SUCCESS:
        localStorage.setItem("token", payload.token);
        return {
          ...state,
          ...payload,
          loading: false,
          isAuthenticated: true,
        };
      case REGISTER_FAIL:
      case AUTH_ERROR:
      case LOGIN_FAIL:
      case LOGOUT:
        localStorage.removeItem("token");
        return {
          ...state,
          token: null,
          loading: false,
          isAuthenticated: false,
        };
      default:
        return state;
    }
  };
  
  export default auth;  