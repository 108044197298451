import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Button,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem } from 'reactstrap';
import { connect } from "react-redux";
import { logout } from '../../actions/auth';

const NavbarComponent = ({logout, isAuthenticated, loading, currentPage}) => {

  const [isOpen, setIsOpen] = useState(false);
  const [hideNavBar, setHideNavbar] = useState(false);

  const authLinks = [
    { href: "/dashboard", title: "Dashboard" }
  ]

  const authLinksDropdown = [
    { href: "/reminded-invoices", title: "Herinnerde facturen" },
    { href: "/expired-invoices", title: "Verlopen facturen" },
    { href: "/wik", title: "Maak WIK brieven" },
    { href: "/active_recurring_companies", title: "Bedrijven met actieve periodieke facturen" }
  ]

  const guestLinks = [
    { href: "/login", title: "Login" },
    { href: "/register", title: "Register" }
  ]

  useEffect(() => {
    if(currentPage.title === 'wikbrief') {
      setHideNavbar(true)
    } else {
      setHideNavbar(false)
    }
  }, [currentPage])

  return (
    <div style={{marginBottom: hideNavBar ? 0 : "50px", display: hideNavBar ? 'none' : 'block'}}>
      <Navbar color="light" light expand="md">
    <NavbarBrand href="/">Premiums MB Tool</NavbarBrand>
    {/* <Button  onClick={(e) => setHideNavbar(true)}>Navbar Verbergen</Button> */}
    <NavbarToggler onClick={(e) => setIsOpen(!isOpen)} />
      <Collapse isOpen={isOpen} navbar>
      <Nav className="ml-auto" navbar>
      {!loading && !isAuthenticated && guestLinks.length > 0 && guestLinks.map(link => {
        return <NavItem>
          <NavLink href={link.href}>{link.title}</NavLink>
        </NavItem>
      })}
      {!loading && isAuthenticated && authLinks.length > 0 && authLinks.map(link => {
        return <NavItem>
          <NavLink href={link.href}>{link.title}</NavLink>
        </NavItem>
      })}

      {!loading && isAuthenticated && authLinksDropdown.length > 0 && <UncontrolledDropdown nav inNavbar>
    <DropdownToggle nav caret>
          Overzichten
        </DropdownToggle>
        <DropdownMenu end>
        {!loading && isAuthenticated && authLinksDropdown.length > 0 && authLinksDropdown.map(link => {
        return <DropdownItem>
          <NavLink href={link.href}>{link.title}</NavLink>
        </DropdownItem>
      })}
        </DropdownMenu></UncontrolledDropdown>
        }
      {!loading && isAuthenticated && 
      <NavItem onClick={(e) => logout()}>
        <NavLink href="/login">Uitloggen</NavLink>
      </NavItem>}
      </Nav>
          </Collapse>
  </Navbar>
    </div>
  )
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  currentPage: state.currentPage
})

NavbarComponent.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  logout: PropTypes.func
}

export default connect(mapStateToProps, { logout })(NavbarComponent)