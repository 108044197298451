import React from 'react'
import { Table } from 'reactstrap'
import SingleNote from './SingleNote'

 const Notes = ({notes}) => {
  return (
    <>
    <Table bordered>
        <thead>
            <tr>
                <th>
                    Geplaatst op
                </th>
                <th>
                    Door
                </th>
                <th>Notitie</th>
            </tr>
        </thead>
        <tbody>
        {notes.map(note => {
            return <SingleNote note={note} />
        })}
        </tbody>
    </Table>
    </>
  )
}

export default Notes;