import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loadActiveRecurringCompanies } from "../../actions/activeRecurringCompanies";
import { Card, CardBody, CardText, CardTitle } from "reactstrap";

const ActiveRecurringCompanies = ({ loadActiveRecurringCompanies, activeRecurringCompanies }) => {


    useEffect(() => {
        loadActiveRecurringCompanies();
    }, []);

    return (
      <>
      <h1>Bedrijven met actieve periodieke facturen</h1>

      
      {activeRecurringCompanies.companies.map(company => {
        return <Card key={company.id} style={{padding: "15px", margin: "25px auto"}}>
            <CardBody>
                <CardTitle tag='h5'>
                    {company.company_name}
                </CardTitle>
            </CardBody>
            <CardText>
                {company.custom_fields && company.custom_fields.map(field => {
                    return <p>{field.name}: {field.value}</p>
                })}
            </CardText>
        </Card>     
      })}
      </>
    )
}

const mapStateToProps = (state) => ({
    activeRecurringCompanies: state.activeRecurringCompanies,
});

ActiveRecurringCompanies.propTypes = {
    activeRecurringCompanies: PropTypes.object.isRequired,
    loadActiveRecurringCompanies: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { loadActiveRecurringCompanies })(ActiveRecurringCompanies);