import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
  } from "./constants";
  import { setAlert } from "./alert";
  import axios from "axios";
  import { setAuthToken } from "../utils/setAuthToken";
  
  // Load user
  export const loadUser = () => async (dispatch) => {
    if (localStorage.getItem("token")) {
      try {
        setAuthToken(localStorage.getItem("token"));
        const res = await axios.get(`/api/auth/`);
        // if(!res.data.verified) {
        //     dispatch({
        //         type: AUTH_ERROR
        //     })
        // }
        dispatch({
          type: USER_LOADED,
          payload: res.data,
        });
      } catch (err) {
        dispatch({
          type: AUTH_ERROR,
        });
      }
    } else {
      dispatch({
        type: AUTH_ERROR,
      });
    }
  };
  
  // Register user
  export const register =
    ({ name, email, password }) =>
    async (dispatch) => {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({ name, email, password });
      try {
        const res = await axios.post(
          "/api/users/",
          body,
          config
        );
        dispatch({
          type: REGISTER_SUCCESS,
          payload: res.data,
        });
        if (res.data.alerts) {
          res.data.alerts.forEach((alert) => {
          dispatch(setAlert(alert));
        });
      }
        dispatch(loadUser());
      } catch (error) {
        if (error.response.data.alerts) {
          error.response.data.alerts.forEach((alert) => {
            dispatch(setAlert(alert));
          });
        }
        dispatch({
          type: REGISTER_FAIL,
        });
      }
    };
  
  // Login User
  export const login = (email, password) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ email, password });
    try {
      const res = await axios.post(
        "/api/auth/",
        body,
        config
      );
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      dispatch(loadUser());
    } catch (error) {
      const alerts = error.response.data.alerts;
      if (alerts) {
        alerts.forEach((alert) => {
          dispatch(setAlert(alert));
        });
      }
      dispatch({
        type: LOGIN_FAIL,
      });
    }
  };
  
  // Logout / Clear profile
  export const logout = () => (dispatch) => {
    dispatch({
      type: LOGOUT,
    });
  };
  