import moment from 'moment';
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { setPage } from '../../actions/currentPage';
const logo = require('../../images/logo.png');
const handtekening = require('../../images/handtekening.png');


export const WikBrief = ({currentWikBrief, setPage}) => {

  useEffect(() => {
    setPage("wikbrief")
  }, [])

  const [totalUnpaid, setTotalUnpaid] = useState(0);

  const [inNL, setInNL] = useState(currentWikBrief.contact_info.country === 'NL');

  const [incassoKosten, setIncassoKosten] = useState(40);

  const getTotalUnpaid = () => {
    let tempTotalUnpaid = 0;
    currentWikBrief.selectedInvoices.forEach(invoice => {
      tempTotalUnpaid += parseFloat(invoice.invoice_unpaid);
    });
    setTotalUnpaid(tempTotalUnpaid);
  }

  useEffect(() => {
    setInNL(currentWikBrief.contact_info.country === 'NL');
    getTotalUnpaid();
    if(totalUnpaid <= 2500 && (totalUnpaid * 0.15) <= 40) {
      setIncassoKosten(40);
    } else if (totalUnpaid <= 2500 && (totalUnpaid * 0.15) > 40) {
      setIncassoKosten(totalUnpaid * 0.15);
    } else if (totalUnpaid > 2500 && totalUnpaid <= 5000) {
      let incassoOverFirst2500 = totalUnpaid * 0.15;
      setIncassoKosten(incassoOverFirst2500);
    } else if (totalUnpaid > 5000) {
      let rest = totalUnpaid - 2500;
      let incassoOverFirst2500 = totalUnpaid * 0.15;
      let incassoOverSecond2500 = rest * 0.1;
      setIncassoKosten(incassoOverFirst2500 + incassoOverSecond2500);
    } else {
      setIncassoKosten(40);
    }
  }, [currentWikBrief, totalUnpaid])

  const formatCurrency = (val) => {
    return '€ '.concat(parseFloat(val).toFixed(2).replaceAll('.00', ',-').replaceAll('.', ','));
  }

  return (
    <div style={{ maxWidth: "900px", margin: "100px auto" }}>
    <div style={{ marginTop: "100px" }}>
        <img src={logo} style= {{ width: "300px", marginBottom: "60px" }} />
    </div>
    <div>
      {currentWikBrief.company_name}<br />
      {currentWikBrief.contact_info.firstname} {currentWikBrief.contact_info.lastname}<br />
      {currentWikBrief.contact_info.street_and_number}<br />
      {currentWikBrief.contact_info.zipcode}, {currentWikBrief.contact_info.city}<br />
      {currentWikBrief.contact_info.country == 'NL' ? "" : currentWikBrief.contact_info.country == "BE" ? "België" : ""}
    </div>
    <div style={{margin: "1em auto"}}>
      <p><strong>Betreft: </strong> Laatste aanmaning openstaande facturen</p>
    </div>
    <div>
      <p>Amsterdam, {moment().format('DD/MM/YYYY')}</p>
    </div>
    <div>
      <p>Geachte heer, mevrouw,</p>
      <p>Uit onze administratie is gebleken dat onderstaande vordering nog steeds niet door u is betaald, ondanks
meerdere herinneringen per email.</p>
    </div>
    <div style={{marginBottom: "1em"}}>
      <table style={{ border: "1px solid black" }}>
        <thead style={{ border: "1px solid black" }}>
          <th style={{ border: "1px solid black", padding: "5px" }}>Factuurnummer</th>
          <th style={{ border: "1px solid black", padding: "5px" }}>Factuurdatum</th>
          <th style={{ border: "1px solid black", padding: "5px" }}>Factuurbedrag</th>
          <th style={{ border: "1px solid black", padding: "5px" }}>Openstaand bedrag</th>
          <th style={{ border: "1px solid black", padding: "5px" }}>Omschrijving</th>
        </thead>
        <tbody>
          {currentWikBrief.selectedInvoices.length > 0 && currentWikBrief.selectedInvoices.map(invoice => {
            return <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black", padding: "5px" }}>{invoice.invoice_id}</td>
              <td style={{ border: "1px solid black", padding: "5px" }}>{moment(invoice.invoice_date).format('DD/MM/YYYY')}</td>
              <td style={{ border: "1px solid black", padding: "5px" }}>{formatCurrency(invoice.invoice_total)}</td>
              <td style={{ border: "1px solid black", padding: "5px" }}>{formatCurrency(invoice.invoice_unpaid)}</td>
              <td style={{ border: "1px solid black", padding: "5px" }}>{invoice.invoice_description}</td>
            </tr>
          })}
        </tbody>
      </table>
    </div>
    <div>
      <p>Wij stellen u in de gelegenheid om de hoofdsom van <strong>{formatCurrency(totalUnpaid)}</strong> alsnog te voldoen binnen 14 dagen vanaf de
dag nadat deze brief bij u is bezorgd</p>
    </div>
    <div>
      <p>Wanneer deze termijn is verstreken wordt de vordering uit handen gegeven aan een incassobureau en stellen
wij u reeds nu voor alsdan- en voor zover nog nodig- in gebreke en delen wij u mede dat u direct na het
verstrijken van deze termijn conform de wet incassokosten verschuldigd bent groot <strong>{formatCurrency(incassoKosten)}</strong>. Tevens maken wij
aanspraak op de alsdan verschuldigde rente</p>
    </div>
    <div>
      <p>Betaling dient te geschieden o.v.v. uw factuurnummer(s) op rekeningnummer NL23 KNAB 0257 7559 42. Bij
voorbaat hartelijk dank voor uw medewerking</p>
    </div>
    <div style={{marginTop: "40px"}}>
      <p>Hoogachtend,</p>
    </div>
    <div>
      <img src={handtekening} style={{ width: "200px", margin: "25px 0" }}/>
    </div>
    <div>
      <p>Afdeling Debiteurenbeheer</p>
    </div>
    <hr>
    </hr>
    <div>
      <p style={{textAlign: "center", color: "#888", fontSize: ".8rem"}}>
      <small>Premiums Websites B.V.| Magneetstraat 7, 1014CC Amsterdam<br />
service@premiumswebsites.nl | 020 6822522<br />
IBAN NL23 KNAB 0257 7559 42 | BIC: KNABNL2H | KvK: 72630752</small>
      </p>
    </div>
    </div>
  )
}

WikBrief.propTypes = {
  currentWikBrief: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  currentWikBrief: state.currentWikBrief,
})


export default connect(mapStateToProps, { setPage })(WikBrief)
