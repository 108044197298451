import React from 'react'
import { Alert } from 'reactstrap';
import PropTypes from "prop-types";
import { connect } from "react-redux";

const AlertComponent = ({alerts}) => {

  console.log(alerts.alertsArray)

  return (
    alerts.alertsArray.length > 0 && alerts.alertsArray.map(alert => {
      return <Alert color={alert.type === 'error' ? 'danger' : alert.type}>{alert.content}</Alert>
    })
  )
}

const mapStateToProps = (state) => ({
  alerts: state.alerts,
});

AlertComponent.propTypes = {
  alerts: PropTypes.object.isRequired
}

export default connect(mapStateToProps, {})(AlertComponent)