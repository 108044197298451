import { LOAD_CURRENT_PAGE, LOAD_CURRENT_PAGE_ERROR } from "../actions/constants";

const initialState = {
    title: ""
};

const currentPage = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_CURRENT_PAGE:
      return {...state, 
        title: payload
    };
    case LOAD_CURRENT_PAGE_ERROR:
        return state;
    default:
      return state;
  }
};

export default currentPage;