import { LOAD_MONEYBIRD_CONTACT, MONEYBIRD_CONTACT_ERROR } from "../actions/constants";

const initialState = {
    contact_id: null,
    company_name: null,
    contact_info: {},
    invoices: [],
    errors: [],
    loading: true
};

const moneybirdContact = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_MONEYBIRD_CONTACT:
      return {...state, 
        contact_id: payload.contact_id,
        company_name: payload.company_name,
        contact_info: payload.contact_info,
        invoices: payload.invoices,
        loading: false
    };
    case LOAD_MONEYBIRD_CONTACT:
        return {...state, 
          contact_id: null,
          company_name: null,
          contact_info: {},
          invoices: [],
          loading: false
        };
    case MONEYBIRD_CONTACT_ERROR:
      return state;
    default:
      return state;
  }
};

export default moneybirdContact;