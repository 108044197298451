import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setAlert } from "../../actions/alert";
import { register } from "../../actions/auth";
import AlertComponent from '../layout/AlertComponent';
import { Row, Col, Button, Form, FormGroup, Label, Input  } from 'reactstrap';
import { Navigate } from 'react-router-dom';

function Register({ setAlert, register, isAuthenticated }) {

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        password: "",
        confirmPassword: ""
    });

    const {name, email, password, confirmPassword} = formData;

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setAlert("Wachtwoorden komen niet overeen", "danger", 3000);
        } else {
            register({name, email, password, confirmPassword});
        }
        
    }

    if (isAuthenticated) {
        return <Navigate to="/dashboard" />;
    }
    
  return (
    <>
        <Row>
            <Col>
                <h1 className="display-3">Register</h1>
            </Col>
        </Row>
        <Row>
            <Col>
                <Form onSubmit={(e) => handleSubmit(e)}>
                    <FormGroup>
                        <Label for="name">Naam</Label>
                        <Input
                            type="text"
                            required
                            name="name"
                            placeholder="Name"
                            value={name}
                            onChange={(e) => handleChange(e)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="email">E-mail</Label>
                        <Input type="email"
                            required
                            name="email"
                            placeholder="E-mail"
                            value={email}
                            onChange={(e) => handleChange(e)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="password">Wachtwoord</Label>
                        <Input 
                            type="password"
                            required
                            name="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => handleChange(e)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="confirmPassword">Wachtwoord bevestigen</Label>
                        <Input 
                            type="password"
                            required
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            onChange={(e) => handleChange(e)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Button color="primary" type="submit">Registreer</Button>
                    </FormGroup>
                </Form>
            </Col>
        </Row>
        <Row>
            <Col>
                <AlertComponent />
            </Col>
        </Row> 
        <Row>
            <p>Heb je al een account? <a href="/login">Inloggen</a></p>
        </Row>
    </>
  )
}

const mapStateToProps = state => ({
    alerts: state.alerts,
    isAuthenticated: state.auth.isAuthenticated,
})

Register.propTypes = {
    setAlert: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
}

export default connect(mapStateToProps, { setAlert, register })(Register)
