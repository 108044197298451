import { LOAD_MONEYBIRD_USERS, MONEYBIRD_USERS_ERROR } from "../actions/constants";

const initialState = {
    users: {},
    errors: [],
    loading: true
};

const moneybirdUsers = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_MONEYBIRD_USERS:
      return {...state, 
        users: payload.data, 
        loading: false
    };
    case MONEYBIRD_USERS_ERROR:
        return {...state, 
            users: {}, 
            errors: payload.data, 
            loading: false
        };
    default:
      return state;
  }
};

export default moneybirdUsers;