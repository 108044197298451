import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { loadMoneybirdContactByCompanyName } from '../../actions/moneybirdContacts'
import { Card, CardBody, CardText, CardTitle, Col, Form, FormGroup, Button, Label, Input } from 'reactstrap'
import SingleInvoice from '../invoices/SingleInvoice'
import { setCurrentWikBrief } from '../../actions/currentWikBrief'
import { useNavigate } from "react-router-dom";

function Wik({moneybirdContact, loadMoneybirdContactByCompanyName, setCurrentWikBrief}) {
    let navigate = useNavigate();
    const [currentContact, setCurrentContact] = useState(moneybirdContact);
    
    const [selectedInvoices, setSelectedInvoices] = useState([]);

    const [wikCreated, setWikCreated] = useState(false);

    const handleInvoiceSelection = (e, invoice) => {
        const tempArr = selectedInvoices;
        if(selectedInvoices.includes(invoice)) {
            tempArr.splice(tempArr.indexOf(invoice));
            setSelectedInvoices(tempArr);
        } else {
            setSelectedInvoices([...selectedInvoices, invoice]);
        }
    }

    const handleCreateWikBrief = (e, moneybirdContact) => {
        e.preventDefault();
        setCurrentWikBrief(moneybirdContact, selectedInvoices);
        setWikCreated(true);
    }

    if(wikCreated) {
        navigate("/wik-brief", { replace: true });
    }

    useEffect(() => {
      loadMoneybirdContactByCompanyName(currentContact)
    }, [currentContact])
    
    let randomNumber = () => {
      Math.floor(Math.random()*(255 + 1))
  }
  function random(number){
      return Math.floor(Math.random()*number);;
  }
  function randomColor(){
   return 'rgba('+random(255)+','+random(255)+','+random(255)+', .33)';    
  }
  const [bgColor, setBgColor] = useState(randomColor());

  return (
    <>
    <h1>Maak WIK Brieven</h1>
    <p>Je kunt zoeken op bedrijfsnaam, alleen openstaande facturen ouder dan 30 dagen worden weergegeven. Ik wil hier een tool bij bouwen die ervoor zorgt dat je automatisch een Wik-brief kunt genereren.</p>
    <Form onSubmit={(e) => {
      e.preventDefault();
      loadMoneybirdContactByCompanyName(currentContact);
      setBgColor(randomColor());
      }}>
        <FormGroup>
            <Label for="currentContact"><strong>Zoek op bedrijfsnaam</strong></Label>
            <Input 
                type="text"
                required
                name="currentContact"
                placeholder="Bedrijfsnaam"
                onChange={(e) => setCurrentContact(e.target.value)}
            />
        </FormGroup>
        <FormGroup>
            <Button color="primary" type="submit">Zoek</Button>
        </FormGroup>
      </Form>      
     
     <Card style={{backgroundColor: bgColor, padding: "15px", margin: "25px auto"}}>
        <CardText>
            <p>Selecteer facturen</p>
       <div style={{marginLeft: "20px", marginBottom: "20px"}}>
       {(moneybirdContact && moneybirdContact.invoices.length > 0) && moneybirdContact.invoices.map(invoice => {
                return <><Input type="checkbox"
                label={invoice.invoice_id}
                onChange={(e) => handleInvoiceSelection(e, invoice) }
            /> {invoice.invoice_id} <br /></>
                })}
       </div>
            <div>
            <Button color="primary" onClick={(e) => handleCreateWikBrief(e, moneybirdContact)}>Maak WIK Brief</Button>
            </div>
        </CardText>
     </Card>
        
        <Card key={moneybirdContact.contact_id} style={{backgroundColor: bgColor, padding: "15px", margin: "25px auto"}}>
            <CardBody>
                <CardTitle tag='h5'>
                    {moneybirdContact.company_name}
                </CardTitle>
            </CardBody>
            <CardText>
                <Card style={{backgroundColor: "transparent", border: "none"}}>
                    <CardTitle>
                    <Button
                        color="info"
                        href={moneybirdContact.contact_info.mblink}
                        target="blank"
                        size="sm"
                    >
                        Naar {moneybirdContact.company_name} in Moneybird
                    </Button>
                    <Button
                        color="primary"
                        href={`https://premstest.freshservice.com/search/all?term=${moneybirdContact.company_name}`}
                        target="blank"
                        size="sm"
                    >
                        Zoek {moneybirdContact.company_name} in CRM
                    </Button>
                    </CardTitle>
                </Card>
            <Card>
                <CardBody>
                    <CardText>
                    {moneybirdContact.contact_info.firstname && moneybirdContact.contact_info.lastname && <><strong>Naam: </strong> {moneybirdContact.contact_info.firstname} {moneybirdContact.contact_info.lastname}<br /></>}
                    {moneybirdContact.contact_info.phone && <><strong>Tel: </strong> {moneybirdContact.contact_info.phone}</>}
                    </CardText>
                </CardBody>
            </Card>
            {moneybirdContact.invoices.map(invoice => {
               return <SingleInvoice invoice={invoice}  />
            })}
            </CardText>
        </Card>     
    </>
  )
}

const mapStateToProps = state => ({
  moneybirdContact: state.moneybirdContact,
  })

Wik.propTypes = {
    moneybirdContact: PropTypes.object,
    loadMoneybirdContactByCompanyName: PropTypes.func,
    setCurrentWikBrief: PropTypes.func
}

export default connect(mapStateToProps, { loadMoneybirdContactByCompanyName, setCurrentWikBrief })(Wik)